.instagramItems {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .instagramItem {
    -webkit-box-flex: 0;
    flex: 0 0 calc(100%/6 - 10px);
    margin: 5px;
    display: block;
    position: relative;
  }
  .instagramImg {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: .25s;
    transition: .25s;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .instagramIcon {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
    opacity: 0;
  }
  .instagramIcon:hover {
    opacity: 1;
    background-color: #00000061;
  }
  @media (max-width: 480px) {
    
    /* CSS */
    .instagramItem{
      flex: 0 0 calc(100%/2 - 10px);
      margin: 5px;
    }
    
  }
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    /* CSS */
    .instagramItem {
      flex: 0 0 calc(100%/3 - 10px);
      margin: 5px;
    }
    
  }

  .errorMessage{
    text-align: center;
    color: red;
    font-size: 18px;
  }